import { useParams } from "react-router-dom";
import { NoteServicePromiseClient } from "../generated/note_grpc_web_pb";
import { NoteByShareRequest, NoteModel } from "../generated/note_pb";
import { useEffect, useState } from "react";
import Editor from "@monaco-editor/react";
import pinkCss from "./share.pink.module.scss";
import darkCss from "./share.dark.module.scss";

export const Share = () => {
  const [note, setNote] = useState<NoteModel>();
  const [error, setError] = useState<unknown>();
  const { key } = useParams();

  useEffect(() => {
    if (!key) {
      return;
    }

    async function getNote() {
      const noteSevice = new NoteServicePromiseClient(
        process.env.REACT_APP_API_URL as string,
        null,
        null
      );
      const request = new NoteByShareRequest();
      request.setKey(key!);
      try {
        setNote(await noteSevice.getNoteByShare(request));
      } catch (e: unknown) {
        setError(e);
      }
    }
    getNote();
  }, [setNote, key]);

  const monacoConfig = {
    automaticLayout: true,
    contextmenu: false,
    domReadOnly: true,
    readOnly: true,
    wordWrap: "on",
    minimap: {
      enabled: false,
    },
  };

  const queryParams = new URLSearchParams(window.location.search);
  const theme = queryParams.get("theme");
  let css = pinkCss;
  switch (theme) {
    case "dark":
      css = darkCss;
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.head.appendChild(link);
      }
      link.href = "/favicon-dark.ico";
      break;
    default:
      break;
  }

  return (
    <main className={css.main}>
      <div className={css.page}>
        <div className={css.maxWidth}>
          <h1 className={css.title}>
            <span>
              {error ? "Something unexpected happend.." : note?.getTitle()}
            </span>
            <span className={css.date}>
              {note?.getLasteditedat().toDate().toLocaleString("nl-NL")}
            </span>
          </h1>
        </div>
        <div className={`${css.maxWidth} ${css.maxHeight}`}>
          <Editor
            defaultValue={
              error
                ? `Error, unable to load content...\n${String(error)}`
                : note?.getContent()
            }
            path={note?.getTitle()}
            options={monacoConfig}
          />
        </div>
      </div>
    </main>
  );
};
