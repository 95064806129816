import css from "./screensot.module.scss";
import desktop from "./screenshot_desktop.png";
import phone from "./screenshot_phone.png";
import phone_alt from "./screenshot_phone_alt.png";

export const Screenshot = () => {
  let imageState = false;
  const imageSources = [phone, phone_alt];

  const toggleImage = (
    event: React.MouseEvent<HTMLImageElement, MouseEvent>
  ) => {
    imageState = !imageState;
    const img = event.nativeEvent.target as HTMLImageElement;
    img.src = imageSources[Number(imageState)];
  };
  return (
    <>
      <img
        className={`${css.desktop} ${css.img}`}
        src={desktop}
        alt="A screenshot of VSCode with NoriNote open"
      />
      <img
        className={`${css.mobile} ${css.img}`}
        src={phone}
        alt="A screenshot of VSCode with NoriNote open"
        onClick={toggleImage}
      />
      <h6 className={`${css.mobile} ${css.img}`}>
        <center>Tap the image to toggle Markdown preview</center>
      </h6>
    </>
  );
};
