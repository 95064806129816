/**
 * @fileoverview gRPC-Web generated client stub for notes
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.4.2
// 	protoc              v3.19.1
// source: note.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');


var google_protobuf_empty_pb = require('google-protobuf/google/protobuf/empty_pb.js')

var google_protobuf_timestamp_pb = require('google-protobuf/google/protobuf/timestamp_pb.js')

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js')
const proto = {};
proto.notes = require('./note_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.notes.NoteServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.notes.NoteServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notes.NoteIdRequest,
 *   !proto.notes.NoteModel>}
 */
const methodDescriptor_NoteService_GetNote = new grpc.web.MethodDescriptor(
  '/notes.NoteService/GetNote',
  grpc.web.MethodType.UNARY,
  proto.notes.NoteIdRequest,
  proto.notes.NoteModel,
  /**
   * @param {!proto.notes.NoteIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notes.NoteModel.deserializeBinary
);


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notes.NoteModel)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notes.NoteModel>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notes.NoteServiceClient.prototype.getNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notes.NoteService/GetNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetNote,
      callback);
};


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notes.NoteModel>}
 *     Promise that resolves to the response
 */
proto.notes.NoteServicePromiseClient.prototype.getNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notes.NoteService/GetNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.google.protobuf.Empty,
 *   !proto.notes.NoteMetaDataList>}
 */
const methodDescriptor_NoteService_GetNotes = new grpc.web.MethodDescriptor(
  '/notes.NoteService/GetNotes',
  grpc.web.MethodType.UNARY,
  google_protobuf_empty_pb.Empty,
  proto.notes.NoteMetaDataList,
  /**
   * @param {!proto.google.protobuf.Empty} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notes.NoteMetaDataList.deserializeBinary
);


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notes.NoteMetaDataList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notes.NoteMetaDataList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notes.NoteServiceClient.prototype.getNotes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notes.NoteService/GetNotes',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetNotes,
      callback);
};


/**
 * @param {!proto.google.protobuf.Empty} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notes.NoteMetaDataList>}
 *     Promise that resolves to the response
 */
proto.notes.NoteServicePromiseClient.prototype.getNotes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notes.NoteService/GetNotes',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetNotes);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notes.SaveNoteRequest,
 *   !proto.notes.NoteModel>}
 */
const methodDescriptor_NoteService_SaveNote = new grpc.web.MethodDescriptor(
  '/notes.NoteService/SaveNote',
  grpc.web.MethodType.UNARY,
  proto.notes.SaveNoteRequest,
  proto.notes.NoteModel,
  /**
   * @param {!proto.notes.SaveNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notes.NoteModel.deserializeBinary
);


/**
 * @param {!proto.notes.SaveNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notes.NoteModel)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notes.NoteModel>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notes.NoteServiceClient.prototype.saveNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notes.NoteService/SaveNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_SaveNote,
      callback);
};


/**
 * @param {!proto.notes.SaveNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notes.NoteModel>}
 *     Promise that resolves to the response
 */
proto.notes.NoteServicePromiseClient.prototype.saveNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notes.NoteService/SaveNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_SaveNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notes.NoteIdRequest,
 *   !proto.google.protobuf.Empty>}
 */
const methodDescriptor_NoteService_DeleteNote = new grpc.web.MethodDescriptor(
  '/notes.NoteService/DeleteNote',
  grpc.web.MethodType.UNARY,
  proto.notes.NoteIdRequest,
  google_protobuf_empty_pb.Empty,
  /**
   * @param {!proto.notes.NoteIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  google_protobuf_empty_pb.Empty.deserializeBinary
);


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.google.protobuf.Empty)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.google.protobuf.Empty>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notes.NoteServiceClient.prototype.deleteNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notes.NoteService/DeleteNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_DeleteNote,
      callback);
};


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.google.protobuf.Empty>}
 *     Promise that resolves to the response
 */
proto.notes.NoteServicePromiseClient.prototype.deleteNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notes.NoteService/DeleteNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_DeleteNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notes.RenameNoteRequest,
 *   !proto.notes.NoteModel>}
 */
const methodDescriptor_NoteService_RenameNote = new grpc.web.MethodDescriptor(
  '/notes.NoteService/RenameNote',
  grpc.web.MethodType.UNARY,
  proto.notes.RenameNoteRequest,
  proto.notes.NoteModel,
  /**
   * @param {!proto.notes.RenameNoteRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notes.NoteModel.deserializeBinary
);


/**
 * @param {!proto.notes.RenameNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notes.NoteModel)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notes.NoteModel>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notes.NoteServiceClient.prototype.renameNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notes.NoteService/RenameNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_RenameNote,
      callback);
};


/**
 * @param {!proto.notes.RenameNoteRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notes.NoteModel>}
 *     Promise that resolves to the response
 */
proto.notes.NoteServicePromiseClient.prototype.renameNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notes.NoteService/RenameNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_RenameNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notes.NoteIdRequest,
 *   !proto.notes.ShareModel>}
 */
const methodDescriptor_NoteService_ShareNote = new grpc.web.MethodDescriptor(
  '/notes.NoteService/ShareNote',
  grpc.web.MethodType.UNARY,
  proto.notes.NoteIdRequest,
  proto.notes.ShareModel,
  /**
   * @param {!proto.notes.NoteIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notes.ShareModel.deserializeBinary
);


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notes.ShareModel)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notes.ShareModel>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notes.NoteServiceClient.prototype.shareNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notes.NoteService/ShareNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_ShareNote,
      callback);
};


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notes.ShareModel>}
 *     Promise that resolves to the response
 */
proto.notes.NoteServicePromiseClient.prototype.shareNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notes.NoteService/ShareNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_ShareNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notes.NoteIdRequest,
 *   !proto.notes.ShareList>}
 */
const methodDescriptor_NoteService_GetShares = new grpc.web.MethodDescriptor(
  '/notes.NoteService/GetShares',
  grpc.web.MethodType.UNARY,
  proto.notes.NoteIdRequest,
  proto.notes.ShareList,
  /**
   * @param {!proto.notes.NoteIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notes.ShareList.deserializeBinary
);


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notes.ShareList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notes.ShareList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notes.NoteServiceClient.prototype.getShares =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notes.NoteService/GetShares',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetShares,
      callback);
};


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notes.ShareList>}
 *     Promise that resolves to the response
 */
proto.notes.NoteServicePromiseClient.prototype.getShares =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notes.NoteService/GetShares',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetShares);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notes.NoteByShareRequest,
 *   !proto.notes.NoteModel>}
 */
const methodDescriptor_NoteService_GetNoteByShare = new grpc.web.MethodDescriptor(
  '/notes.NoteService/GetNoteByShare',
  grpc.web.MethodType.UNARY,
  proto.notes.NoteByShareRequest,
  proto.notes.NoteModel,
  /**
   * @param {!proto.notes.NoteByShareRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notes.NoteModel.deserializeBinary
);


/**
 * @param {!proto.notes.NoteByShareRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notes.NoteModel)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notes.NoteModel>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notes.NoteServiceClient.prototype.getNoteByShare =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notes.NoteService/GetNoteByShare',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetNoteByShare,
      callback);
};


/**
 * @param {!proto.notes.NoteByShareRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notes.NoteModel>}
 *     Promise that resolves to the response
 */
proto.notes.NoteServicePromiseClient.prototype.getNoteByShare =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notes.NoteService/GetNoteByShare',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetNoteByShare);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notes.NoteIdRequest,
 *   !proto.notes.NoteModel>}
 */
const methodDescriptor_NoteService_GetVersionedNote = new grpc.web.MethodDescriptor(
  '/notes.NoteService/GetVersionedNote',
  grpc.web.MethodType.UNARY,
  proto.notes.NoteIdRequest,
  proto.notes.NoteModel,
  /**
   * @param {!proto.notes.NoteIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notes.NoteModel.deserializeBinary
);


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notes.NoteModel)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notes.NoteModel>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notes.NoteServiceClient.prototype.getVersionedNote =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notes.NoteService/GetVersionedNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetVersionedNote,
      callback);
};


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notes.NoteModel>}
 *     Promise that resolves to the response
 */
proto.notes.NoteServicePromiseClient.prototype.getVersionedNote =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notes.NoteService/GetVersionedNote',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetVersionedNote);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.notes.NoteIdRequest,
 *   !proto.notes.NoteMetaDataList>}
 */
const methodDescriptor_NoteService_GetVersionedNotes = new grpc.web.MethodDescriptor(
  '/notes.NoteService/GetVersionedNotes',
  grpc.web.MethodType.UNARY,
  proto.notes.NoteIdRequest,
  proto.notes.NoteMetaDataList,
  /**
   * @param {!proto.notes.NoteIdRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.notes.NoteMetaDataList.deserializeBinary
);


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.notes.NoteMetaDataList)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.notes.NoteMetaDataList>|undefined}
 *     The XHR Node Readable Stream
 */
proto.notes.NoteServiceClient.prototype.getVersionedNotes =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/notes.NoteService/GetVersionedNotes',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetVersionedNotes,
      callback);
};


/**
 * @param {!proto.notes.NoteIdRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.notes.NoteMetaDataList>}
 *     Promise that resolves to the response
 */
proto.notes.NoteServicePromiseClient.prototype.getVersionedNotes =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/notes.NoteService/GetVersionedNotes',
      request,
      metadata || {},
      methodDescriptor_NoteService_GetVersionedNotes);
};


module.exports = proto.notes;

