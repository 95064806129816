import css from "./install.module.scss";

export const Install = () => {
  return (
    <div className={css.container}>
      <a
        href="https://git.ironsm4sh.nl/shared/notes/-/jobs/artifacts/master/file/Notes.Extension/norinote-0.0.2.vsix?job=build_vsix"
        className={css.text}
      >
        Install NoriNote
      </a>
    </div>
  );
};
