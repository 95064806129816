import css from "./footer.module.scss";

export const Footer = () => {
  return (
    <div className={css.footer}>
      <div>
        {/* TODO: Add link to the source */}
        <a href="https://git.ironsm4sh.nl/shared/notes">
          NoriNote is Free software available under the AGPL 3.0 license.
        </a>
        <span>Copyright 2024 Ironsm4sh.nl</span>
      </div>
    </div>
  );
};
