import css from "./headline.module.scss";

export const Headline = () => {
  return (
    <>
      <h1 className={`${css.textCenter} ${css.title}`}>
        All your notes,
        <br />
        in all your projects.
      </h1>
      <h2 className={`${css.textCenter} ${css.subTitle}`}>
        Synchronize all of your notes in Visual Studio Code
      </h2>
    </>
  );
};
