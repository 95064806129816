import { Footer } from "../components/footer/footer";
import { Headline } from "../components/headline/headline";
import { Install } from "../components/install/install";
import { Screenshot } from "../components/screenshot/screenshot";
import css from "./main.module.scss";

export const Main = () => {
  return (
    <main className={css.main}>
      <div className={css.content}>
        <Headline />
        <Screenshot />
        <Install />
      </div>
      <Footer />
    </main>
  );
};
